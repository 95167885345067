import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/projet1.jpg";
import ProjectImg2 from "../../assets/img/projects/projet2.jpg";
import ProjectImg3 from "../../assets/img/projects/newProjet.jpg";
import nhox_pr from "../../assets/img/nhox_pr2.jpg";
import AddImage2 from "../../assets/img/add/add2.png";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import loSer from "../../assets/lottie/services.json"
import project from "../../assets/lottie/project2.json"

export default function Projects() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {

    window.removeEventListener('resize', handleResize);
};
}, []);

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
        <div style={{
            display: "flex",
            flexDirection: windowWidth>730?"row":"column",
            justifyContent: windowWidth>730?"space-between":"center",
            alignItems: "center"
          }}>
          <HeaderInfo>
            <h1 className="font40 extraBold">Quelques projets</h1>
            <p className="font13">
              Nos projets visent à révolutionner la qualité de l'air grâce à des technologies innovantes et des solutions personnalisées.
              <br />
              Nous nous engageons à améliorer la santé et le bien-être en offrant des environnements plus propres et plus sains.
            </p>
          </HeaderInfo>
          <Lottie 
          animationData={project}
          loop={true}
          style={{
            height:200,
            width:200
          }}
          />
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="BURKINA GOOD AEREA"
                text="En raison du manque de données et de capteurs concernant la qualité de l'air.
                Notre projet nommée BURKINA GOOD AEREA consiste à installer des capteurs de qualité de l'air
                à travers la ville pour surveiller en temps réel les niveaux de pollution.
                Les données collectées seront accessibles à toute la population, permettant à chacun de suivre
                la qualité de l'air et de prendre des mesures informées pour protéger sa santé. Grâce à cette initiative,
                nous visons à promouvoir la transparence et à sensibiliser la communauté sur l'importance de l'air pur. "
                action={() => {}}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="GOOD AIR = GOOD HEALTH"
                text="Ce projet utilise un dispositif personnel et mobile pour 
                analyser la relation entre les composants de l'air et la santé des individus. 
                En collectant des données sur la qualité de l'air, nous visons à développer des 
                solutions portables, telles que des purificateurs personnels ou des alertes en temps réel, 
                pour améliorer le bien-être en réponse aux niveaux de pollution."
                action={() => {}}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Votre projet"
                text="Actuellement, nous n'avons pas de troisième projet communautaire ou humanitaire en cours.
                 Cependant, nous vous invitons à nous contacter si vous souhaitez vous associer à nous ou proposer une idée.
                 Nous sommes ouverts à de nouvelles collaborations et initiatives pour élargir notre impact."
                action={() => {}}
              />
            </div>
          </div>
          
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Voir plus" action={() => {}} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={nhox_pr} style={{height:600, width:300}} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Les objets connectés de NHOX ne seraient utiles sans interface web et mobile</h4>
              <h2 className="font40 extraBold">Application Web et mobile</h2>
              <p className="font12">
              L'application web est destinée aux personnes qui souhaitent obtenir des informations 
              sur leur dispositif fixe installé dans leurs locaux. 
              L'application mobile, quant à elle, est conçue pour les utilisateurs de dispositifs mobiles personnels,
               leur permettant de recevoir des alertes partout et à tout moment en fonction de leur emplacement.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Commencer" action={() => {}} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contactez nous" action={() => {}} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
