import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import air1 from "../../assets/img/persAir.jpg"
import Hand from "../../assets/lottie/hand.json"
import Question from "../../assets/lottie/question.json"
import Start from "../../assets/lottie/start.json"
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Lottie from "lottie-react";


export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <Lottie 
          animationData={Hand}
          loop={true}
          style={{
            height:200,
            width:200
          }}
          />
          <Lottie 
          animationData={Question}
          loop={true}
          style={{
            height:200,
            width:200
          }}
          />
          </div>
          <h1 className="extraBold font60">Sachez où et quand vous protégez.</h1>
          <HeaderP className="font13 semiBold">
          NHOX-Company a pour mission de fournir des solutions technologiques innovantes afin de protéger
           les populations contre la pollution de l'air. Nos produits vous permettent de connaître 
           en temps réel les composants présents dans l'air,
           que ce soit à l'intérieur ou à l'extérieur, et de purifier l'air lorsque sa qualité se dégrade.
          </HeaderP>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <BtnWrapper>
            <FullButton title="Commencer" />
          </BtnWrapper>
          <Lottie 
          animationData={Start}
          loop={true}
          style={{
            height:200,
            width:200
          }}
          />
          </div>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={air1} alt="office" style={{ height:650, width:507}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>De la même manière que nous nous soucions de ce que nous mangeons,
                   nous devons en faire de même pour ce que nous respirons. 🌿</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Souglimani Traore</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>

    </Wrapper>
    
  );
}


const Wrapper = styled.section`
  padding-top: 20px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


