import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button, Fab, Autocomplete, TextField, InputLabel, MenuItem, useMediaQuery, useTheme} from '@mui/material';
//import AddIcon from '@mui/icons-material/Add.js';
//import 'bootstrap/dist/js/bootstrap'
import iconIt from '../../assets/img/iconIt.png';
import './composants.css';
import './Navbar.css';
import logo from '../../assets/img/logo_nhox.png'


import {useNavigate } from "react-router-dom";

import NavigationIcon from '@mui/icons-material/Navigation';


var BACKEND_URL = 'https://backend.bt-webview.com/api/';


export default function Nav(props) {

    const{ id_user } = props;
    const{ ecole } = props;
    const{ id_annee } = props;
    const [activeLink, setActiveLink] = useState('');

    const [compteUser, setCompteUser]= useState([]);
    const [defaultSection, setDefaultSection]= useState([]);
    const [backendErrorMessage, setBackendErrorMessage]= useState([])

    const [ listSection,setListSection] = useState([])
    const [ selectSection,setSelectSection] = useState([])

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}choix_pro/${ecole}/${id_annee}`)
        .then(({data}) => {
           // console.log(data);
            setListSection(data);
            //setDefaultSection(data.find(section => section.id_section === id_section));
            //console.log(defaultSection)
        }).catch ((error) =>
        {
            //setBackendErrorMessage(error.response.data.error);
            //console.log(error)
        });
      } catch (error) {
        console.log(error)
      }
  }
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLinkClick = (link) => {
        setActiveLink(link);
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
        setIsOpen(false)
    };



    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }

    });


    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    
    return (
      
      <div className="navbar">
      <div className="navbar-brand" onClick={() => handleLinkClick('Accueil')}>
        <img src={logo} style={{width:'40px', height:'40px', marginRight:2}}></img>
        <span>NHOX</span>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        {/*<div className="navbar-item" onClick={() => handleLinkClick(`salle/${id_section}`)}>
          Salle
        </div>*/}
        {!isSmallScreen ?
        <div className="navbar-item" onClick={() => navigate('/Exposition')}>
          Expositions
        </div>
        :
        <div></div>
}
          
        <div className="navbar-right">
        <TextField type='text' 
                label='Zone'
                value={'Maison H1'}
                style={{ minWidth: 170, maxWidth: 250 }}
                select
                onChange={(e)=> {
                    
                }}
            >
                <MenuItem key={'Maison H1'} value={'Maison H1'}>
                {'Maison H1'}
              </MenuItem>
              <MenuItem key={'Service Ouaga 2000'} value={'Service Ouaga 2000'}>
                {'Service Ouaga 2000'}
              </MenuItem>              
            </TextField>
          <Fab
            variant="extended"
            sx={{ backgroundColor: '#ED2B2A', 
                color: 'white', 
                marginTop: 0,
               fontWeight:'bold',
              '&:hover': {
          color: 'red', // Change this to your desired hover color
        },
       }}
            className="navbar-item"
            onClick={() => navigate(`/`)}
          >
            Déconnexion
          </Fab>
        </div>
      </div>
    </div>
    );
}