import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Connexion from "./screens/connexion.js";
import Dashboard from "./screens/dashboard.js";
import Lottie from "lottie-react";
import lottie from "./assets/lottie/headerLottie.json"
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import Exposition from "./screens/exposition.js";
import Statisques from "./screens/statistiques.js";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },

  {
    path: "/Connexion",
    element: <Connexion />,
  },

  {
    path: "/Accueil",
    element: <Dashboard />,
  },

  {
    path: "/Exposition",
    element: <Exposition />,
  },

  {
    path: "/Exposition/Statistiques",
    element: <Statisques />,
  },

]);



export default function App() {

  return (
    <RouterProvider router={router} />
  );
}

