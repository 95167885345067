import React, { useState,useEffect, useRef, } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import {Tab,Grid,Fab,Button, ListItemIcon,Divider,List,Typography,AccordionDetails ,AccordionSummary,Accordion, Card, CardContent,CardMedia, Box, CircularProgress ,Stack, Tooltip, Container, Autocomplete, TextField, ListItem} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate, useParams } from "react-router-dom";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Nav from '../components/Nav/nav.js';
import FloatingMenu from '../components/Nav/floatingMenu.js';

import B from '../assets/img/1.png';
import B2 from '../assets/img/2.png';
import hand from '../assets/img/3.png';
import laptop from '../assets/img/4.png';
import lock from '../assets/img/5.png';
import T from '../assets/img/6.png';
import techni from '../assets/img/7.png';
import wallet from '../assets/img/8.png';
import img9 from '../assets/img/9.png';
import img10 from '../assets/img/10.png';
import AirIcon from '@mui/icons-material/Air'; // Utiliser une icône pour représenter l'air, ou remplacer par votre image
import MyLocationIcon from '@mui/icons-material/NearMe';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoodIcon from '@mui/icons-material/Mood';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SickIcon from '@mui/icons-material/Sick';


const loadingImages = [
    B,
    T,
    techni,
    laptop,
    lock,
    hand,
    B2,     
    wallet,
    img9,
    img10
   // Ajoutez autant d'images que nécessaire
 ];

var BACKEND_URL = 'https://backend.bt-support.net/api/';
let valueIqa='';

export default function Dashboard() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { id_user }=useParams()
    const [iqa, setIqa]= useState();
    const [listIqa, setListIqa]= useState();
  
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const hours2 = String(today.getHours() + 2).padStart(2, '0');
  
    const start_date = `${year}-${month}-${day} ${hours2}:00:00`;
    const end_date = `${year}-${month}-${day} 23:59:59`;


    const [backendErrorMessage, setBackendErrorMessage] = useState();
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    
  
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 3);
  
  // Calculer la date de fin (3 jours après aujourd'hui)
  const endDate = new Date(today);
  endDate.setDate(today.getDate() + 3);
  
  // Formatage des dates
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0 à 11
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const [backgroundColor, setBackgroundColor] = useState('#ffa726');
  
    const calculIqa = async () => {
      try{
        await axios.post(`${BACKEND_URL}data_nhox/calculeIQA`, {
          start_date: start_date,
          end_date: end_date
        })
        .then(({data}) => {
          console.log(start_date);
          console.log(end_date);
            console.log(data);
            setIqa(data);
            valueIqa=data.iqa;
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
            console.log(error)
        });
      } catch (error) {
        console.log(error)
      }
    }


    const request = async () => {
  
      try{
      await axios.post(`${BACKEND_URL}data_nhox/calculeIQA`, {
          start_date: start_date,
          end_date: end_date
        })
        .then(({data}) => {
          console.log(start_date);
          console.log(end_date);
            console.log(data);
            setIqa(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
            console.log(error)
        });

      await axios.post(`${BACKEND_URL}data_nhox/calculeStat`, {
        start_date: formattedStartDate,
        end_date: formattedEndDate
      })
      .then(({data}) => {
        console.log(formattedStartDate);
        console.log(formattedEndDate);
          console.log(data);
          setListIqa(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
          console.log(error)
      });
    } catch (error) {
      console.log(error)
    }
     
  
  }

  function getNextThreeDays() {
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const today = new Date();
    let result = [];
    

    for (let i = 0; i < 4; i++) {
        let currentDay = new Date(today);
        currentDay.setDate(today.getDate() + i);
        result.push(daysOfWeek[currentDay.getDay()]);
    }
    
    return result;
}

function getNextFiveHours() {
  const now = new Date();
  const hours = [];

  for (let i = 1; i <= 4; i++) {
      const nextHour = new Date(now.getTime() + i * 60 * 60 * 1000);
      const formattedHour = nextHour.getHours().toString().padStart(2, '0') + ':00';
      hours.push(formattedHour);
  }

  return hours;
}
  
  useEffect(() => {
      request();
    const timer = setTimeout(() => {
      // Mettez à jour l'état pour arrêter le chargement après 3 secondes
      setLoading(false);
    }, 3000); // 3000 millisecondes = 3 secondes
  
    // Nettoyez le timer lorsque le composant est 
     // Nettoyez le timer lorsque le composant est démonté
  const interval = setInterval(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
  }, 300);

  const intervalId = setInterval(calculIqa, 3000);

  const daysResult=getNextThreeDays()
  setDays(daysResult);

  const hoursResult=getNextFiveHours()
  setHours(hoursResult);
    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  

    return () => {
      clearTimeout(timer);
     clearInterval(interval);
     clearInterval(intervalId)
      window.removeEventListener('resize', handleResize);
  };
  }, []);

  
  useEffect(() => {
    switch (valueIqa) {
      case 'Bonne':
        setBackgroundColor('green');
        break;
      case 'Modéré':
        setBackgroundColor('#FFB22C');
        break;
      case 'Mauvais':
        setBackgroundColor('#E85C0D');
        break;
      case 'Très mauvais':
        setBackgroundColor('#FF8A8A');
        break;
      case 'Dangereux':
        setBackgroundColor('#7C00FE');
        break;
      default:
        setBackgroundColor('#ffa726');
    }
  }, [valueIqa]);

  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const aqi = 85; // Exemple d'indice AQI
  const aqiStatus = "Modéré"; // Statut basé sur l'AQI
  const pollutants = [
      { name: 'PM2.5', value: 35, unit: 'µg/m³' },
      { name: 'PM10', value: 45, unit: 'µg/m³' },
      { name: 'Ozone (O3)', value: 20, unit: 'ppb' },
      { name: 'NO2', value: 15, unit: 'ppb' },
  ];
  
  if(!loading) {
  
      return (
        <div>
        <Nav id_user={id_user}/>
        <FloatingMenu />
        <Box
            sx={{
              width: '90%',
              minHeight: 100,
              backgroundColor: '', // Bleu transparent
              borderRadius: '10px', // Légèrement arrondi
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '2px', // Espacement interne
              margin:'20px',
              border: '2px solid rgba(0, 128, 255, 0.7)', // Bordure légèrement plus opaque
            }}
          >
            <MyLocationIcon sx={{ color: 'black' }} />
            <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
                            Maison H1
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
                            Ouagadougou, Burkina Faso
                        </Typography>
                        
                    </Grid>            
          </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                backgroundColor: 'white',
                minHeight: '100vh',
                paddingBottom: 10
            }}
        >
            {/* Indice de Qualité de l'Air Principal */}
            <Card sx={{ width: '98%',
               marginBottom: 3,
                backgroundColor: 
                backgroundColor,
                 textAlign: 'center',
                  padding: 2 }}>
            <CardContent>
                <Grid container alignItems="center" spacing={2}>
                    {/* Image ou icône à gauche */}
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                        <AirIcon sx={{ fontSize: 60, color: 'white' }} /> {/* Remplacez par votre image si nécessaire */}
                    </Grid>

                    {/* Indice de qualité de l'air au centre */}
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white' }}>
                            {iqa.iqa_value.toFixed(0)}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                            IQA
                        </Typography>
                        
                    </Grid>

                    {/* État à droite */}
                    <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{ color: 'white', textAlign: 'right' }}>
                          {iqa.iqa}
                      </Typography>
                      <Box sx={{ minWidth:120, backgroundColor: 'white', padding: 1, marginTop: 1, borderRadius:'8px' }}>
                          <Typography sx={{color: 'black',fontWeight:'bold', fontSize:15}}>
                            Primordial : Co2
                          </Typography>
                      </Box>
                      {/*<Box sx={{ minWidth:120, backgroundColor: 'white', padding: 1, marginTop: 1, borderRadius:'8px' }}>
                          <Typography sx={{color: 'black',fontWeight:'bold', fontSize:15}}>
                            PM2.5 : {iqa.avg_pm25} ug/m³
                          </Typography>
                      </Box>*/}
                    </Grid>
                </Grid>  
                             
                </CardContent>
                    </Card>

                {/*<Box sx={{ width: '100%',
                   typography: 'body1',
                   borderRadius: 2,
                   boxShadow: 3,
                   backgroundColor: '#f5f5f5',
                    }}>
                <Typography sx={{fontWeight:'bold', 
              fontSize:20, 
              fontFamily:'cursive',
              marginBottom:2,
              padding:2}}>Prévisions sur 3 jours 🌤️📅</Typography> 
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={days[1]} value="1" />
                        <Tab label={days[2]} value="2" />
                        <Tab label={days[3]} value="3" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Stack spacing={2} direction='row'>
                      {hours.map((row, index) => (
                        <Stack spacing={1} justifyContent='center'>
                          <Typography textAlign={'center'}>{row}</Typography>
                          <Box style={{backgroundColor:index==3?'#ffa726':'#399918',
                            minWidth:55,
                            padding:2,
                            borderRadius:'5px',
                            justifyContent:'center',
                          }}>
                            <Typography color={'white'} 
                            textAlign={'center'}>
                              {index==3 ?'50-100':'0-50'}
                              </Typography>
                          </Box>
                          <Box sx={{justifyContent:'center',minWidth:55,
                            padding:2,}}>
                          {index==3?
                          <MoodBadIcon sx={{color:'#ffa726'}} />
                          :
                          <MoodIcon sx={{color:'green'}} />
                          }
                          </Box>
                        </Stack>
                      ))}
                        
                      </Stack>
                    </TabPanel>
                    <TabPanel value="2">
                    <Stack spacing={2} direction='row'>
                      {hours.map((row, index) => (
                        <Stack spacing={1} justifyContent='center'>
                          <Typography textAlign={'center'}>{row}</Typography>
                          <Box style={{backgroundColor:index==1?'#ffa726':'#399918',
                            minWidth:55,
                            padding:2,
                            borderRadius:'5px',
                            justifyContent:'center',
                          }}>
                            <Typography color={'white'} 
                            textAlign={'center'}>
                              {index==1 ?'50-100':'0-50'}
                              </Typography>
                          </Box>
                          <Box sx={{justifyContent:'center',minWidth:55,
                            padding:2,}}>
                          {index==1?
                          <MoodBadIcon sx={{color:'#ffa726'}} />
                          :
                          <MoodIcon sx={{color:'green'}} />
                          }
                          </Box>
                        </Stack>
                      ))}
                        
                      </Stack>
                    </TabPanel>
                    <TabPanel value="3">
                    <Stack spacing={2} direction='row'>
                      {hours.map((row, index) => (
                        <Stack spacing={1} justifyContent='center'>
                          <Typography textAlign={'center'}>{row}</Typography>
                          <Box style={{backgroundColor:index==4?'red':'#399918',
                            minWidth:55,
                            padding:2,
                            borderRadius:'5px',
                            justifyContent:'center',
                          }}>
                            <Typography color={'white'} 
                            textAlign={'center'}>
                              {index==4 ?'101-150':'0-50'}
                              </Typography>
                          </Box>
                          <Box sx={{justifyContent:'center',minWidth:55,
                            padding:2,}}>
                          {index==4?
                          <SentimentVeryDissatisfiedIcon sx={{color:'red'}} />
                          :
                          <MoodIcon sx={{color:'green'}} />
                          }
                          </Box>
                        </Stack>
                      ))}
                        
                      </Stack>
                    </TabPanel>
                  </TabContext>
                </Box> */} 
                <Divider sx={{width:'90%',color:'black', height:2}}></Divider> 
                <Box style={{marginTop:50}}>
                <Typography sx={{fontWeight:'bold', 
              fontSize:20, 
              fontFamily:'serif',

              marginBottom:2}}>Guide de l'Indice de la Qualité de l'Air 🛡️ </Typography>
                <Accordion expanded={true}>
                <AccordionSummary
                  expandIcon={<MoodIcon sx={{color:'green'}}/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
          <Typography style={{fontWeight:'bold'}}>Précautions en cas de très bonne IQA (0-50)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Aucun besoin particulier. 
                Vous pouvez continuer vos activités habituelles en plein air.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<MoodIcon sx={{color:'green'}}/>}
                aria-controls="panel2-content"
                id="panel2-header"
              >
              <Typography style={{fontWeight:'bold'}}>Précautions en cas de bonne IQA (51-100)</Typography>          
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Les personnes sensibles aux allergies ou aux problèmes respiratoires 
                pourraient envisager de réduire le temps passé dans cette environnement si elles ressentent des symptômes.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<SentimentVeryDissatisfiedIcon sx={{color:'#FFB22C'}} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
              <Typography style={{fontWeight:'bold'}}>Précautions en cas d'IQA modéré (101-150)</Typography>          
              </AccordionSummary>
              <AccordionDetails>
                <Typography>                
                <Typography style={{fontWeight:'bold'}}>Précautions</Typography>Limiter les activités extérieures intenses, 
                surtout pour les personnes sensibles. 
                Assurez-vous de surveiller les symptômes respiratoires et 
                réduisez le temps passé dans cette environnement si nécessaire.
                <Typography style={{fontWeight:'bold'}}>Conséquences</Typography> Les personnes sensibles peuvent commencer à ressentir des effets.
                Les risques pour les personnes ayant des problèmes respiratoires,
                 des enfants et des personnes âgées peuvent augmenter.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<MoodBadIcon sx={{color:'#E85C0D'}} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
              <Typography style={{fontWeight:'bold'}}>Précautions en cas de mauvaise IQA (151-200)</Typography>          
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <Typography style={{fontWeight:'bold'}}>Précautions</Typography>
                 Limitez les activités dans cette environnement pour tous, en particulier pour les personnes sensibles, 
                les enfants et les personnes âgées. Veillez à rester activer votre purificateur d'air
                 pendant les périodes où la qualité 
                de l'air est mauvaise.
                Consultez un médécin après une forte exposition. 
                <Typography style={{fontWeight:'bold'}}>Conséquences</Typography>
                Tout le monde peut commencer à ressentir des effets. 
                Les personnes sensibles peuvent ressentir des effets plus graves.                
              </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<SickIcon sx={{color:'#FF8A8A'}}/>}
                aria-controls="panel2-content"
                id="panel2-header"
              >
              <Typography style={{fontWeight:'bold'}}>Précautions en cas de très mauvaise IQA (201-300)</Typography>          
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <Typography style={{fontWeight:'bold'}}>Précautions</Typography>
                Évitez de sortir autant que possible. 
                Limitez les activités physiques et évitez de faire des efforts en extérieur.
                 Assurez-vous que votre espace intérieur est bien ventilé et utilisez des purificateurs d'air. 
                 Suivez les recommandations des autorités locales.
                 Obligation de consulter.
                <Typography style={{fontWeight:'bold'}}>Conséquences</Typography>
                Effets pour la santé importants pour tout le monde. 
                Les risques pour les personnes sensibles et la population générale sont significatifs.                
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<SickIcon sx={{color:'#7C00FE'}}/>}
                aria-controls="panel2-content"
                id="panel2-header"
              >
              <Typography style={{fontWeight:'bold'}}>{"Précautions en cas de dangeureuse IQA (>300)"}</Typography>          
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <Typography style={{fontWeight:'bold'}}>Précautions</Typography>
                Restez à l'intérieur autant que possible. 
                Évitez toute activité physique à l'extérieur. 
                Assurez-vous que vos espaces intérieurs sont protégés contre 
                la pollution extérieure en utilisant des purificateurs d'air et en gardant les fenêtres fermées.
                 Obligation de consulter.
                <Typography style={{fontWeight:'bold'}}>Conséquences</Typography>
                Risques graves pour la santé.
                Les risques pour les personnes sensibles et la population générale sont significatifs.                
                </Typography>
              </AccordionDetails>
            </Accordion>
            </Box>        
                    </Box>
</div>
)
}  
else
{
    return(
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // 100% de la hauteur de la vue
      }}
    >
      <Stack direction={'column'} alignItems={'center'} spacing={1}>
          <img
            src={loadingImages[currentImage]}
            alt="Loading"
            style={{ width: 100, height: 100}}
          />
          <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
      </Stack>
    </Box>

    );

}
}
