import styled from "styled-components";
import React, { useState, useEffect } from 'react';

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

import air1 from "../../assets/img/persAir.jpg"
import air2 from "../../assets/img/puAir.jpg"
import persAir2 from "../../assets/img/persAir2.jpg"
import objet1 from "../../assets/img/objet1.jpg"

import loSer from "../../assets/lottie/services.json"
import Lottie from "lottie-react";

export default function Services() {


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {

    window.removeEventListener('resize', handleResize);
};
}, []);
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
        <div style={{
            display: "flex",
            flexDirection: windowWidth>730?"row":"column",
            justifyContent: windowWidth>730?"space-between":"center",
            alignItems: "center"
          }}>
          <HeaderInfo>
            <h1 className="font40 extraBold">Nos Services</h1>
            <p className="font13">
            Avec une équipe d'experts et de médecins, NHOX offre une gamme de services dédiés à la 
              <br />
              prévention, à la protection, et à l'amélioration des conditions respiratoires.
            </p>
          </HeaderInfo>
          <Lottie 
          animationData={loSer}
          loop={true}
          style={{
            height:200,
            width:200
          }}
          />
          </div>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Visualisation de la qualité de l'air"
                subtitle="Grâce à nos dispositifs de pointe, nous vous offrons une surveillance
                 précise de la qualité de l'air intérieur de votre espace.
                 Vous pouvez ainsi connaître en temps réel l'état de l'air 
                 que vous respirez et identifier les composants présents,
                 pour mieux protéger votre santé et celle de vos proches."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Alertes et prédictions de la qualité de l'air"
                subtitle="Notre service d'alertes et de prédictions de la qualité de l'air 
                vous informe en temps réel des variations et des pics de pollution.
                 Grâce à des alertes personnalisées, 
                vous pouvez anticiper et protéger 
                votre santé face aux changements dans l'air que vous respirez. "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <ServiceBox
                icon="browser"
                title="Purification de l'air intérieur"
                subtitle="Notre service de purification de l'air utilise des technologies de pointe pour éliminer les polluants,
                 allergènes, et agents pathogènes de votre environnement intérieur. 
                 Nous créons un espace plus sain et sûr, 
                 réduisant les risques pour la santé et améliorant votre confort respiratoire.
                 Respirez mieux, vivez mieux. "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer"
               title="Conseils et étude de terrain"
                subtitle="Notre service de conseils et d'étude de terrain 
                offre une évaluation approfondie de la qualité de l'air dans votre environnement.
                 Nous analysons les conditions spécifiques de votre espace et fournissons
                  des recommandations personnalisées pour améliorer la qualité de l'air. Avec notre expertise,
                 vous obtenez des solutions pratiques et efficaces pour 
                 créer un environnement intérieur plus sain et plus confortable." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Quelques mots sur les méthodes NHOX</h4>
                <h2 className="font40 extraBold">Notre creativité</h2>
                <p className="font12">
                NHOX utilise une combinaison de méthodes innovantes pour garantir une qualité de l'air optimale.
                 Nous employons des technologies de pointe pour surveiller et d'
                  analyser les niveaux de pollution, de particules fines et d'autres contaminants.
                   Nos dispositifs de purification avancés éliminent efficacement les polluants de l'air. En outre, 
                nos experts réalisent des études de terrain approfondies pour fournir des recommandations personnalisées.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Commencer" action={() => {}} />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contactez nous" action={() => {}} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={air2} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={persAir2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={objet1} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={air2} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;