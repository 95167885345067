import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../components/Nav/nav.js';
import axios from 'axios';
import {CircularProgress,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import { format } from 'date-fns';
import Fuse from 'fuse.js';

const loadingImages = [
         
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';
  var countData=1;

export default function Statisques() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listStat, setlistStat] = useState([]);
    const { id_user } = useParams();

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listStat.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listStat.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;    

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };

   

   

    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}data_nhox/index`)
        .then(({data}) => {
            console.log(data);
            setlistStat(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 2000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 200);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize', handleResize);
      };

      }, []);


      const [searchQuery, setSearchQuery] = useState('');
     // Configuration de Fuse.js
 const fuseOptions = {
  keys: ['timestamp'], // Les champs à rechercher
  includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listStat, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // Effectuer la recherche et mettre à jour les résultats
  const results = fuse.search(query);

  if (query==''){
    //setApproData(results.map((result) => result.item));
    request()
  }
  else{
    setlistStat(results.map((result) => result.item));
  }

};

      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if(!loading) {
    return (
      <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
      <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>

            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}} 
              onClick={() => {navigate('/Exposition')}} >
                    RETOUR                      
            </Button>
            <div></div>
            </Stack>
           
            <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: windowWidth>700?5:2, 
                marginRight: windowWidth>700?5:2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>   
            <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Statisques de la zone
            </Stack>  
            <div style={{marginBottom: -10}}>
                <TextField type="text" 
                      value={searchQuery} 
                      onChange={handleSearchChange} 
                      placeholder="Rechercher...">
                </TextField>
                </div>                          
            <Stack alignItems='center'>            
            
                {windowWidth>700?
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Statisques</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 2.5 (µg/m³)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 10 (µg/m³)</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Co2 (ppm)</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >NO2 (µg/m³)</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >O3 (µg/m³)</TableCell>                                                           
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Température (°C)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Humidité (%)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pression (Pa)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date et heure</TableCell>                                                                                 
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600 , color:row.pm25>26?'red':'black'}}>{row.pm25}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600, color:row.pm10>51?'red':'black'}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600, color:row.co2>1000?'red':'black'}}>{row.co2}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, color:row.pm10>51?'red':'black'}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600, color:row.co2>1000?'red':'black'}}>{row.co2}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.temperature} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.humidity} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.pressure} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{format(new Date(row.timestamp), 'dd-MM-yyyy HH:MM:SS')} </TableCell>                                      
                                      
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Statisques</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                            <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#BAD1C2'}} >Date et heure</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="center" colSpan={2} style={{fontWeight: 600, color:'#5E1675'}}>{`${format(new Date(row.timestamp), 'dd-MM-yyyy')}`}
                                {` Heure : ${format(new Date(row.created_at), 'HH:MM')}`}
                                </TableCell>     
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 2.5 (µg/m³)</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 10 (µg/m³)</TableCell> 
                              </TableRow>
                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600, color:'#5E1675'}}>{row.pm25}</TableCell>     
                              <TableCell align="center" style={{fontWeight: 600}}>{row.pm10}</TableCell>                                      
                              </TableRow>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >NO2 (µg/m³)</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >O3 (µg/m³)</TableCell>                                      
                              </TableRow>
                              <TableRow>                                  
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600, }}>{row.co2}</TableCell>
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Co2</TableCell>                                                                                                                                 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Température (°C)</TableCell>                                                                                 
                              </TableRow> 

                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600}}>{row.co2}</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.temperature} </TableCell>                              
                               </TableRow>
                               <TableRow>                                
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pression (Pa)</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Humidité (%)</TableCell>
                                </TableRow>    
                                <TableRow>
                              <TableCell align="center" style={{fontWeight: 600}}>{row.pressure}</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.humidity} </TableCell>                              
                               </TableRow>                                            
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class="flex-row" style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total : {listStat.length}</h5> 
                  </div>
          </div>
            </Stack>            
            </Stack>                                      
          </div>
    );
   
    function nextPage(){
      if(firstIndex+10 < listStat.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
     
  }
}