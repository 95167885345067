import React from "react";
import styled from "styled-components";
// Assets


import { get } from "react-scroll/modules/mixins/scroller";
import { useEffect, useState } from "react";
import loSer from "../../assets/lottie/services.json"
import visAir from "../../assets/lottie/visAir.json"
import alertAir from "../../assets/lottie/alertAir.json"
import purAir from "../../assets/lottie/purAir.json"
import consAir from "../../assets/lottie/consAir.json"
import Lottie from "lottie-react";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = visAir;
      break;
    case "monitor":
      getIcon = alertAir;
      break;
    case "browser":
      getIcon = purAir;
      break;
    case "printer":
      getIcon = consAir;
      break;
    default:
      getIcon = loSer;
      break;
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {

    window.removeEventListener('resize', handleResize);
};
}, []);

  return (
    <Wrapper className="flex flexColumn">
      <Lottie 
          animationData={getIcon}
          loop={true}
          style={{
            height:120,
            width:120,
            //margin:" 0 ",
          }}
          />
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;