import React, { useState,useEffect, useRef, } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table,Grid,Fab,Button, ListItemIcon,Divider,List,Typography,AccordionDetails ,AccordionSummary,Accordion, Card, CardContent,CardMedia, Box, CircularProgress ,Stack, Tooltip, Container, Autocomplete, TextField, ListItem} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate, useParams } from "react-router-dom";

import Nav from '../components/Nav/nav.js';
import FloatingMenu from '../components/Nav/floatingMenu.js';


import B from '../assets/img/1.png';
import B2 from '../assets/img/2.png';
import hand from '../assets/img/3.png';
import laptop from '../assets/img/4.png';
import lock from '../assets/img/5.png';
import T from '../assets/img/6.png';
import techni from '../assets/img/7.png';
import wallet from '../assets/img/8.png';
import img9 from '../assets/img/9.png';
import img10 from '../assets/img/10.png';
import AirIcon from '@mui/icons-material/Air'; // Utiliser une icône pour représenter l'air, ou remplacer par votre image
import { format } from 'date-fns';
import Fuse from 'fuse.js';


const loadingImages = [
    B,
    T,
    techni,
    laptop,
    lock,
    hand,
    B2,     
    wallet,
    img9,
    img10
   // Ajoutez autant d'images que nécessaire
 ];

var BACKEND_URL = 'https://backend.bt-support.net/api/';
var countData=1;
export default function Exposition() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { id_user }=useParams()
    const [iqa, setIqa]= useState();
    const [listIqa, setListIqa]= useState();
  
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
  
    const start_date = `${year}-${month}-${day} 00:00:00`;
    const end_date = `${year}-${month}-${day} 23:59:59`;
    const [backendErrorMessage, setBackendErrorMessage] = useState()
  
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 3);
  
  // Calculer la date de fin (3 jours après aujourd'hui)
  const endDate = new Date(today);
  endDate.setDate(today.getDate() + 3);
  
  // Formatage des dates
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0 à 11
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const navigate = useNavigate();


  
  const [listStat, setlistStat] = useState([]);
  let [currentPage, setCurrentPage] =useState(1);    
  const dataPerPage = 5;
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex - dataPerPage;
  const data = listStat.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(listStat.length /dataPerPage);
  const numbers = [...Array(nPage+1).keys()].slice(1);
  const maxPagesToShow = windowWidth>700?5:3;    

  const getPageNumbersToShow = () => {
    const startPage = Math.max(1, currentPage - maxPagesToShow);
    const endPage = Math.min(nPage, currentPage + maxPagesToShow);
  
    return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
  };

  
    const request = async () => {
  
      try{
            await axios.get(`${BACKEND_URL}data_nhox/index`)
            .then(({data}) => {
                console.log(data);
                setlistStat(data);
            }).catch ((error) =>
            {
                setBackendErrorMessage(error.response.data.error);
            });
        
      await axios.post(`${BACKEND_URL}data_nhox/calculeIQA`, {
        start_date: start_date,
        end_date: end_date
      })
      .then(({data}) => {
        console.log(start_date);
        console.log(end_date);
          console.log(data);
          setIqa(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
          console.log(error)
      });
  
      await axios.post(`${BACKEND_URL}data_nhox/calculeStat`, {
        start_date: formattedStartDate,
        end_date: formattedEndDate
      })
      .then(({data}) => {
        console.log(formattedStartDate);
        console.log(formattedEndDate);
          console.log(data);
          setListIqa(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
          console.log(error)
      });
    } catch (error) {
      console.log(error)
    }
     
  
  }
  
  useEffect(() => {
      request();
    const timer = setTimeout(() => {
      // Mettez à jour l'état pour arrêter le chargement après 3 secondes
      setLoading(false);
    }, 3000); // 3000 millisecondes = 3 secondes
  
    // Nettoyez le timer lorsque le composant est 
     // Nettoyez le timer lorsque le composant est démonté
  const interval = setInterval(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
  }, 300);

    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  
    return () => {
      clearTimeout(timer);
     clearInterval(interval)
      window.removeEventListener('resize', handleResize);
  };
  }, []);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);


  const aqi = 85; // Exemple d'indice AQI
  const aqiStatus = "Modéré"; // Statut basé sur l'AQI
  const pollutants = [
      { name: 'PM2.5', value: 3.5,tN: 0.625, unit: 'µg/m³' },
      { name: 'PM10', value: 5.5,tN: 1.9, unit: 'µg/m³' },
      { name: 'Co2', value: 1200,tN: 1000, unit: 'ppm' },
      { name: 'Ozone (O3)', value: 24,tN: 12.5, unit: 'µg/m³' },
      { name: 'NO2', value: 150,tN: 200, unit: 'µg/m³' },
  ];
  
  if(!loading) {
  
      return (
        <div>
        <Nav id_user={id_user}/>
        <FloatingMenu />
        {/*<Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                backgroundColor: 'white',
                minHeight: '100vh',
                
            }}
        >        
            {/* Informations Détaillées 
            <Typography sx={{fontWeight:'bold', 
              fontSize:24, 
              fontFamily:'cursive',
              marginBottom:2}}>Expositions horaires</Typography>
            <Grid container spacing={2} sx={{ width: '98%' }}>
                {pollutants.map((pollutant, index) => (
                    <Grid item xs={12} sm={6} key={index} onClick={(e)=> {
                      navigate('/Exposition/Statistiques');
                    }}>
                        <Card>
                            <CardContent>
                                <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                                    {pollutant.name}
                                </Typography>
                                <Typography variant="h6" sx={{ color: '#42a5f5' }}>
                                    {pollutant.value} {pollutant.unit} / Taux normale: {pollutant.tN}  {pollutant.unit}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                
            </Grid>
            <Button sx={{marginTop:'20px'}} variant="contained" color="primary"
            onClick={(e)=> {
              navigate('/Exposition/Statistiques');
            }}>
                  Voir le tableau de données 
                </Button>
        </Box>*/}        
    <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 2,
                marginLeft: windowWidth>700?5:2, 
                marginRight: windowWidth>700?5:2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>   
            <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Statisques de la zone
            </Stack>  
            {/*<div style={{marginBottom: -10}}>
                <TextField type="text" 
                      value={searchQuery} 
                      onChange={handleSearchChange} 
                      placeholder="Rechercher...">
                </TextField>
                </div>      */}                    
            <Stack alignItems='center'>            
            
                {windowWidth>700?
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-180, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={10}>Statisques</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 2.5 (µg/m³)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 10 (µg/m³)</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Co2 (ppm)</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >NO2 (µg/m³)</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >O3 (µg/m³)</TableCell>                                                           
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Température (°C)</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Humidité (%)</TableCell>                                      
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date et heure</TableCell>                                                                                 
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600 , color:row.pm25>26?'red':'black'}}>{row.pm25}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600, color:row.pm10>51?'red':'black'}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600, color:row.co2>1000?'red':'black'}}>{row.co2}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, color:row.pm10>51?'red':'black'}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600, color:row.co2>1000?'red':'black'}}>{row.co2}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.temperature} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.humidity} </TableCell>                                      
                                      <TableCell align="left" style={{fontWeight: 600,}}>{format(new Date(row.timestamp), 'dd-MM-yyyy HH:MM:SS')} </TableCell>                                      
                                      
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Statisques</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                            <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#BAD1C2'}} >Date et heure</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="center" colSpan={2} style={{fontWeight: 600, color:'#5E1675'}}>{`${format(new Date(row.timestamp), 'dd-MM-yyyy')}`}
                                {` Heure : ${format(new Date(row.created_at), 'HH:MM')}`}
                                </TableCell>     
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 2.5 (µg/m³)</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >PM 10 (µg/m³)</TableCell> 
                              </TableRow>
                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600, color:'#5E1675'}}>{row.pm25}</TableCell>     
                              <TableCell align="center" style={{fontWeight: 600}}>{row.pm10}</TableCell>                                      
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >NO2 (µg/m³)</TableCell> 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >O3 (µg/m³)</TableCell>                                      
                              </TableRow>
                              <TableRow>                                  
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.pm10}</TableCell>                                        
                                      <TableCell align="center" style={{fontWeight: 600, }}>{row.co2}</TableCell>
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Co2 (ppm)</TableCell>                                                                                                                                 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Température (°C)</TableCell>                                                                                 
                              </TableRow> 

                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600, color:'#5E1675'}}>{row.co2}</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.temperature} </TableCell>                              
                               </TableRow>
                               <TableRow>                                
                                    <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Humidité (%)</TableCell>
                                </TableRow>    
                                <TableRow>
                                      <TableCell align="center"  colSpan={2} style={{fontWeight: 600,}}>{row.humidity} </TableCell>                              
                               </TableRow>                                            
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class="flex-row" style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total : {listStat.length}</h5> 
                  </div>
          </div>
            </Stack>            
            </Stack>
            <Box
      sx={{
        width: windowWidth>700 ? '95%' : '90%',
        padding: windowWidth>700 ? 2 : 4,
        marginTop: '20px',
        marginBottom:20,
        marginRight:2,
        marginLeft:2,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#f5f5f5',
        textAlign: 'left'
      }}
    >
      <Typography variant="h6" gutterBottom>
        Le saviez-vous ? 💡
      </Typography>
      <Typography sx={{fontWeight:'bold',marginBottom:'10px'}} >
      Les effets combinés de la pollution de l’air ambiant et de la pollution de l’air intérieur 
      sont associés à 
      <span style={{fontWeight:'bold', color:'red'}}> 6,7 millions de décès prématurés par an. </span>
      </Typography>
      <Typography variant="body1" paragraph>
      La pollution atmosphérique est l’un des plus grands risques environnementaux pour la santé.
       En diminuant les niveaux de pollution atmosphérique, les pays peuvent
        réduire la charge de morbidité imputable aux accidents vasculaires cérébraux,
         aux cardiopathies, au cancer du poumon
       et aux affections respiratoires, chroniques ou aiguës, y compris l’asthme.
        </Typography>
      <Button variant="contained" color="primary" onClick={(e) =>{
         window.open('https://www.who.int/fr/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health#:~:text=Les%20effets%20combin%C3%A9s%20de%20la,(ext%C3%A9rieur)%20dans%20le%20monde.', '_blank');
      }} >
        En savoir plus
      </Button>
    </Box>
</div>

);

function nextPage(){
  if(firstIndex+10 < listStat.length)
  {
    setCurrentPage(currentPage + 1);
    countData=countData+10;
  }
}

function prePage(){
  if(firstIndex-1>0)
  {
    setCurrentPage(currentPage - 1);
    countData=countData-10;
    console.log(countData)
  }
}

function changeCPage(id){
  setCurrentPage(id);
  countData = ((id*10)-9)
}
}  
else
{
    return(
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // 100% de la hauteur de la vue
      }}
    >
      <Stack direction={'column'} alignItems={'center'} spacing={1}>
          <img
            src={loadingImages[currentImage]}
            alt="Loading"
            style={{ width: 100, height: 100}}
          />
          <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
      </Stack>
    </Box>

    );

}
}
