import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nos différents produits</h1>
            <p className="font13">
              NHOX propose plusieurs produits conviviales et adapte aux exigences des utilisateurs.
              <br />
              Voici 3 de nos produits far et plus payé.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="95.000 FCFA"
                title="House-NHOX V1"
                text="Dispositif fixe, adapte pour les grands espaces (compatible avec la solution web)"
                offers={[
                  { name: "Mesure de la qualité de l'air", cheked: true },
                  { name: "Prédiction de la qualité de l'air", cheked: true },
                  { name: "Detection des composants (PM 2.5, PM 10, COx, No)", cheked: true },
                  { name: "Alerte lors des mauvaises qualité de l'air", cheked: true },
                  { name: "Mesure de la température ", cheked: true },
                  { name: "Visualisation statistiques", cheked: true },
                  { name: "Couverture : 250 mètres", cheked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                icon="monitor"
                price="50.000 FCFA"
                title="Personal-NHOX V1"
                text="Dispositif mobile, adapté pour usage personnel (compatible avec l'application mobile)"
                offers={[
                  { name: "Mesure de la qualite de l'air", cheked: true },
                  { name: "Prédiction de la qualité de l'air", cheked: true },
                  { name: "Detection des composants (PM 2.5, PM 10, COx, No)", cheked: true },
                  { name: "Alerte lors des mauvaises qualite de l'aire", cheked: true },
                  { name: "Mesure de la temperature ", cheked: true },
                  { name: "Visualisation statistiques", cheked: true },
                  { name: "Couverture : 15 mètres", cheked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="75.000 FCFA"
                title="Purificateur d'air NHOX"
                text="Ce purificateur est adapte aux petits espace"
                offers={[
                  { name: "Filtration multi-étages (Préfiltre, Filtre HEPA, Filtre à charbon actif)", cheked: true },
                  { name: "Technologie UV-C pour neutraliser les bactéries, virus etc...", cheked: true },
                  { name: "Adaptable avec a votre House-NHOX V1", cheked: true },
                  { name: "Affichage numérique", cheked: true },
                  { name: "Fonctionnalités intelligentes", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




