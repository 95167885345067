import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { useNavigate } from 'react-router-dom';

function FloatingMenu() {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleNavigation = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/Accueil');
                break;
            case 1:
                navigate('/Exposition');
                break;
            case 2:
                console.log('')
                break;
            default:
                break;
        }
    };

    return (
        <Paper sx={{ position: 'fixed', zIndex:1000, bottom: 0, left: 0, right: 0, display: { xs: 'block', md: 'none' } }} elevation={3}>
            <BottomNavigation value={value} onChange={handleNavigation}>
                <BottomNavigationAction label="Mon Air" icon={<HomeIcon />} />
                <BottomNavigationAction label="Exposition" icon={<AccessibilityNewIcon />} />
                {/*<BottomNavigationAction label="Alerte" icon={<NotificationImportantIcon />} />*/}
            </BottomNavigation>
        </Paper>
    );
}

export default FloatingMenu;
