import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import {Fab,Button, ListItemIcon,Divider,List,Typography,AccordionDetails ,AccordionSummary,Accordion, Card, CardContent,CardMedia, Box, CircularProgress ,Stack, Tooltip, Container, Autocomplete, TextField, ListItem} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate } from "react-router-dom";


import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

//import it_person from '../images/it_person.svg';
//import '../configuration/config';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Admini from '@mui/icons-material/AccountBalance';
import Stat from '@mui/icons-material/SignalCellularAlt';
import Mail from '@mui/icons-material/ContactMail';
import Carte from '@mui/icons-material/CreditCard';
import Tablec from '@mui/icons-material/TableChart';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import bg10 from '../assets/img/bg1.jpg'
import light from '../assets/img/logo_nhox.png'
import nhox from '../assets/img/logo_nhox.png'





var BACKEND_URL = 'https://backend.bt-support.net/api/';
gsap.registerPlugin(TextPlugin);

export default function Connexion() {

    const [load,setLoad]= useState();
    const [listAnne_pro, setListAnne_pro]= useState({})
    const [anne_pro, setAnne_pro]= useState('')
    const [userData, setUserData] = useState({
        email: '',
        password: ''
        // Ajoutez d'autres champs selon vos besoins
    });

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const[dataU, setDataU] = useState('')

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    //const history = useHistory();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    

    const request = async () => {
        try{
        /*setLoad(true)
          await axios.get(`${BACKEND_URL}annee_pro`)
          .then(({ data }) => {
            setListAnne_pro(data);
            setLoad(false)
            setAnne_pro(data[0])
            console.log(data);
          }).catch((error) => {
            setBackendErrorMessage(error.response.data.error);
          });*/
        } catch (error) {
          console.log(error)
        }
      }


      useEffect(() => {

        try {
          request();
          
        } catch (error) {
          console.log(error)
        }
      
        const ref = document.querySelector(".anime");
        const ref2 = document.querySelector(".img2");
        const fabRef = document.querySelector(".fabRef");
        
        gsap.timeline().to(ref, {
          duration: 1,
          text: "Bienvenue",
          color:'#0D9276',          
          fontFamily:'monospace',
          ease: "expo.inOut",
          delay: 2,
          
        }).to(ref, {
          duration: 1,
          text: "Connectez-vous",
          color:'#378CE7',          
          fontFamily: windowWidth>700?'cursive':'serif',
          ease: "expo.inOut",
          delay: 2,
          
        });
        
        gsap.to(ref2, {
          rotation: 360,
          duration: 5,
          repeat: -1,
          ease: "linear",
        });

        if (windowWidth < 700) {
          gsap.to(fabRef, {
            y: -20,
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 1.5,
          });
        }

        console.log(ref)
        console.log(ref2)
      
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
      };
        
      }, []);

    const handleSubmit = async (e) => {
      
          setLoad(true);
        try {
            e.preventDefault();
            await axios.post(`${BACKEND_URL}login`, userData)
                .then(({data}) => {
                    console.log(data);
                    setDataU(data)
                    navigate(`/Accueil`);
                }).catch ((error) =>{
                    setLoad(false);
                if (error.response) {
                    // Le serveur a renvoyé une réponse avec un code d'état qui ne se situe pas dans la plage 2xx
                    console.log('Erreur du serveur:66666666', error);
                    setBackendErrorMessage(error.response.data.error);
                } else if (error.request) {
                    // La requête a été faite mais aucune réponse n'a été reçue
                    console.log('Aucune réponse reçue du serveur:', error.request);
                    setBackendErrorMessage('Aucune réponse reçue du serveur.');
                } else {
                    // Autre type d'erreur
                    console.log('Erreur de la requête:', error.message);
                    setBackendErrorMessage('Une erreur est survenue lors de la requête.');
                }
            });
            
        } catch (error) {
            console.log(error)
        }       
    };

    const [expanded, setExpanded] = React.useState(false);

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };
  
    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4" alignItems='center' style={{}}>
                    {/* Colonne de gauche (Formulaire) */}
                    
                    
                    <div class="p-2 rounded " style={{minHeight: 600,marginTop: 80, alignItems:'center'}}>
                    <CardMedia
                        sx={{ height: 100 ,  backgroundSize:'contain' }}
                        image={nhox}
                       
                        title="Fonctionnalités"
                      />
                        
                        <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8,
                    flexGrow: 1,  // Permet à la box de grandir pour occuper tout l'espace disponible
                    width: '100%',  // Assure que la box occupe toute la largeur
                    height: '100%',
                    backgroundColor:'white'
                }}
                 >
                <Typography className='anime' component="h1" variant="h5">
                    NHOX Solutions
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"                        
                        autoComplete="email"
                        autoFocus
                        value={userData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        type="password"
                        id="password"                        
                        autoComplete="current-password"
                        value={userData.password}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, }}
                        onClick={(e)=>{
                          handleSubmit()
                        }}
                    >
                        Se connecter
                    </Button>
                </Box>
                <Typography style={{alignItems:'center', 
                      fontFamily:'cursive', 
                      fontWeight:'bold',
                      marginTop: 10}}> Sachez où et quand vous protégez</Typography>
            </Box>
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                                      
                    </div>
                   
                    </div>
                    
                
                <div class="col-sm-8" style={{backgroundImage: `url(${bg10})`, backgroundSize:'cover',minHeight:'100%'}}>
                    {/* Colonne de droite (Image) */}
                    <div class="container-fluid bg-green vh-100 d-flex justify-content-center align-items-center" style={{ borderRadius: '20px' , }}>
                    {/* Contenu de votre conteneur ici */}
                    <Card sx={{ maxWidth:windowWidth>700?455:300, minWidth:windowWidth>700?350:250, }}>
                      <CardMedia
                        sx={{ height: 150 ,  backgroundSize:'contain' }}
                        image={light}
                       
                        title="Fonctionnalités"
                      />
                      <CardContent>
                                            
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography style={{color:'red', fontFamily:windowWidth>700?'cursive':'serif', fontWeight:''}}>A propos de NHOX-Company</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              <ListItem style={{color:'#009FBD', fontFamily:windowWidth>700?'cursive':'serif', fontWeight:''}}>
                              NHOX-Company a pour mission de proposer des solutions technologiques aux populations 
                              pour leur permettre de se protéger contre la pollution de l'air.
                              </ListItem>
                              <Divider />
                              <ListItem style={{color:'#009FBD', fontFamily:windowWidth>700?'cursive':'serif', fontWeight:''}}>
                              Contact: +226 55 21 40 43 / +226 64 36 95 93
                              </ListItem>
                              <Divider />
                              <ListItem style={{color:'#009FBD', fontFamily:windowWidth>700?'cursive':'serif', fontWeight:''}}>
                                <span>Site web : <a href='/'  target="_blank" rel="noopener noreferrer">
                                 NHOX.com</a>
                                 </span>
                              </ListItem>
                              <Divider />
                                    </List>
                          </AccordionDetails>
                        </Accordion>
                      </CardContent>
                     
                    </Card>
                    </div>
                </div>
            </div>
    </div>
);
}
/*
else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}*/
